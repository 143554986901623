<template>
  <header class="head-background">
    <div class="top-cont">
      <p class="title text-max">{{hotelticket.chineseName||data.hotelName}}</p>
      <p>
        <span v-if="roomticket.name">{{roomticket.name}}</span>
        <span v-if="roomticket.name" style="padding:0 .5rem">|</span>
        <span v-if="roomticket.windows">{{roomticket.windows}}</span>
        <span v-if="roomticket.windows" style="padding:0 .5rem">|</span>
        <span v-if="roomticket.area">面积：{{roomticket.area}}㎡</span>
        <span v-if="data.roomTypeName">{{data.roomTypeName}}</span>
        <span v-if="data.roomTypeName" style="padding:0 .5rem">|</span>
        <span v-if="data.corp">服务商：{{data.corp}}</span>
      </p>
      <p v-if="hotelticket.tel||data.hotelTel">联系电话：{{hotelticket.tel||data.hotelTel}}</p>
      <p v-if="hotelticket.address||data.hotelAddress">
        <a class="van-ellipsis" style="color:#fff" :href="'https://uri.amap.com/marker?position='+(hotelticket.longitude||data.longitude)+','+(hotelticket.latitude||data.latitude)+'&name='+(hotelticket.chineseName||data.hotelName)+'&callnative=1'">
        酒店地址：{{hotelticket.address||data.hotelAddress}}<van-icon name="location-o" />
        </a>
      </p>
      <el-tooltip class="item" effect="dark" v-if="roomticket.ratePlans[0].refundDesc||roomticket.ratePlans[0].refundType||data.refundDesc||data.refundType" :content="roomticket.ratePlans[0].refundDesc||roomticket.ratePlans[0].refundType||data.refundDesc||data.refundType" placement="bottom">
        <p class="warning1">
          <van-icon name="info-o" />
          <span class="van-ellipsis">
            {{roomticket.ratePlans[0].refundDesc||roomticket.ratePlans[0].refundType||data.refundDesc||data.refundType}}
          </span>
        </p>
      </el-tooltip>
    </div>
  </header>
</template>

<script>
export default {
  name: "OrderHotelHead",
  props: {
    data: {
      default: () => {
        return {};
      }
    },
    hotelOrderInfo: {
      default: () => {
        return { checkInDate: "", departDate: "" };
      }
    },
    roomticket: {
      default: () => {
        return {
          bedType: "",
          name: "",
          broadNet: "",
          windows: "",
          ratePlans: [{ refundType: "" }]
        };
      }
    },
    hotelticket: {
      default: () => {
        return { chineseName: "" };
      }
    }
  },
};
</script>

<style lang="less" scoped>
.top-cont {
  padding: 10px 16px;
  color: #ffffff;
  h4,
  p {
    margin: 8px 0 2px;
    font-size: 14px;
    display: flex;
    align-items: center;
    .van-icon {
      padding: 1px 2px;
    }
  }
  .title {
    margin: 4px 0 0;
    transform: scale(1);
    text-align: left;
    padding: 0;
  }
}
.warning1 {
  color: #ffccb5;
}
</style>